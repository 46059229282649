import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const BasicAudio = makeShortcode("BasicAudio");
const ButtonDialog = makeShortcode("ButtonDialog");
const Grid = makeShortcode("Grid");
const Img = makeShortcode("Img");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "helping-your-teen-to-stay-connected"
    }}>{`Helping your teen to stay connected`}</h2>
    <p>{`For most teens, nothing is more important than spending time with their friends. Socialising with peers is important for your teen’s development and mental health.`}</p>
    <p>{`If the pandemic and associated restrictions prevent your teen from engaging in their normal social activities, it’s important for them to find alternative ways to stay connected. Discuss with your teen how they are staying in touch with their friends while following the local regulations and restrictions. If needed, brainstorm some additional ideas with your teen.`}</p>
    <Typography variant="h4" color="primary" mdxType="Typography">
  <b>Teens speak.</b>
    </Typography>
    <p>{`How has the pandemic affected you socially? What is the hardest part about being away from friends? Hear what Sophia (14) and Zoe (15) have to say.`}</p>
    <BasicAudio src="/audios/Teens_speak_social_challenges_pandemic.mp3" mdxType="BasicAudio" />
    <ButtonDialog linkText="Read Transcript" title={<i>Transcript:</i>} mdxType="ButtonDialog">
  <b>
    How has the pandemic affected you socially? What is the hardest part about being away from friends? Sophia (14) and
    Zoe (15) say:
  </b>
  <br />
  <br />
  <b>Sophia:</b> Before I start, I know you’re probably thinking “these teens have so many apps and technologies to stay
  connected, they shouldn’t complain.” And I mean, you’re kind of right, if it wasn’t for Snapchat, Instagram and Facetime
  I would’ve gone completely insane! But being online is SO different to in person, like online you can put on a different
  persona and it’s hard to get across how you really feel through a tweet or a text. You need totally different social skills,
  and you don’t get the deeper connections with people like you do in person.
  <br />
  <br />
  My parents really don’t seem to get this. I mean, they don’t even really use social media that much, and they don’t
  see the things that we do. So it’s just not the same for them.
  <br />
  <br />
  <b>Zoe:</b> Actually, being on social media all the time isn’t good for me. There’s such high expectations for how your
  Insta feed should look and how you should look. It’s hard sometimes seeing all these influencers with perfect skin and
  perfect bodies and feeling like we should also look like that. And then there’s the bad news, like you can just keep scrolling
  and there’s always more…
  <br />
  <br />
  So yeah, we are lucky to have this technology because they didn’t “back in the day”, but that doesn’t make it any
  easier to be isolated from my friends. I really worry that I’m gonna lose friends during this pandemic, like, if I
  can’t see them, they might forget about me, or when we go back to normal it just isn’t the same anymore? Some of my
  friends live close together, so they can go for walks and things all the time, and when I see that posted online I get
  upset.
  <br />
  <br />
  Also, I worry that I’ll be terrible at socialising when I get back to school…like what if I forget how to talk to people
  in person? Ugh. I miss sleepovers, going to the movies and just hanging out with my friends!
    </ButtonDialog>
    <Typography variant="h4" color="primary" mdxType="Typography">
  <b>Staying connected with your teen</b>
    </Typography>
    <p>{`It’s also important for `}<b>{`you`}</b>{` to spend regular, one-on-one time with your teen, even if the whole family is home together all the time. Think outside the box `}{`—`}{` this might be a good opportunity to try out some new activities together! Try to involve your teen in brainstorming ideas for fun activities you can do together.`}</p>
    <Grid container spacing={2} mdxType="Grid">
  {['Gardening', 'Video call relatives and friends', 'Cook or bake', 'Learn new hobbies together', 'Do something together to help others', 'Do an outdoor physical activity together'].map((item, idx) => <Grid key={idx} xs={6} md={4} item mdxType="Grid">
      <Grid style={{
          display: 'flex',
          backgroundColor: '#ebebeb',
          borderRadius: '20px',
          height: '96px',
          padding: '8px 16px',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }} mdxType="Grid">
        <Typography mdxType="Typography">{item}</Typography>
      </Grid>
    </Grid>)}
    </Grid>
    <br />
    <Grid container justify="center" mdxType="Grid">
  <Grid xs={8} md={4} item mdxType="Grid">
    <Img src="/images/m2/8-cooking.svg" alt="cooking with child " mdxType="Img" />
  </Grid>
  <Grid xs={8} md={4} item mdxType="Grid">
    <Img src="/images/m6/31.svg" alt="riding a bike with friends" mdxType="Img" />
  </Grid>
  <Grid xs={6} md={2} item mdxType="Grid">
    <Img src="/images/m2/9-yoga.svg" alt="yoga" mdxType="Img" />
  </Grid>
    </Grid>
    <p>{`For more ideas, check out `}<i>{`The Together List`}</i>{` in the module `}<GoTo to="/dashboard" mdxType="GoTo"><i>{`Nurture Roots & Inspire Wings`}</i></GoTo>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      